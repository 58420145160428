import { useRef } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Home';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

function App() {
  
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/*' element={<Home />}>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
